<template>
  <v-app>
    <Loader v-if="$store.getters.getLoadingStatus"></Loader>
    <Snackbar></Snackbar>
    <transition name="fade" mode="out-in">
      <router-view class="child-view" />
    </transition>
    <AppAction />
  </v-app>
</template>

<script>
export default {
  name: "FullScreenLayout",
  components: {
    Loader: () => import("~ui/Loader/Loader"),
    Snackbar: () => import("~ui/Snackbar/Snackbar"),
    AppAction: () => import("~cp/AppAction/AppAction"),
  },
};
</script>

<style src="./Animation.scss" lang="scss" scoped></style>
