const meta = {
  auth: true,
  pageInfo: {
    title: "Mes documents",
    subtitle: "La bibliothèque",
  }
}

export default [
  {
    path: "/document",
    component: () => import("@/views/Documents/DocumentRouterView"),
    children: [
      {
        path: "",
        name: "DocumentListOfQuotaStart",
        component: () => import("@/views/Documents/Documents/DocumentsPage"),
        meta
      },
      {
        path: ":quotaId",
        name: "DocumentListOfQuota",
        component: () => import("@/views/Documents/Documents/DocumentsPage"),
        meta
      },
    ],
  },
];
