import { protectedAxios } from "./helpers";

export class QuotaService {

  static getQuotas(projectId) {
    return protectedAxios.get(`/quotas/`, {
      params: {
        project_id: projectId
      }
    });
  }

  static createQuota(quota) {
    return protectedAxios.post(`/quotas/`, {
      price_stage_1: 20,
      price_stage_2: 20,
      price_stage_3: 60,
      ...quota
    });
  }

  static getQuotaById(quotaId) {
    return protectedAxios.get(`/quotas/${quotaId}`);
  }

  static updateQuotaById(quotaId, quota) {
    return protectedAxios.put(`/quotas/${quotaId}`, quota);
  }

  static deleteQuota(quotaId) {
    return protectedAxios.delete(`/quotas/${quotaId}`);
  }

  static generatePdf({ html, name, details }) {
    return protectedAxios.post(`/quotas/pdf`, { html, details }, {
      responseType: 'blob',
    }).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
      .catch(error => console.log(error));
  }

  static getStatuses() {
    return protectedAxios.get(`quotas/statuses`);
  }

  static getQuotaData(quotaId) {
    return protectedAxios.get(`/quota_data`, {
      params: {
        quota_id: quotaId,
      },
    });
  }

  static pdfContent() {
    return protectedAxios.get(`/pdf_content/`);
  }
}
