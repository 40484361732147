export default {
  en: {
    city: "City of the property to renovate",
    state_of_object: "Current state of the property",
    product_class: "Your product range",
    need_an_architect: "An architect's need",
    budget: "Your budget",
    type_of_renovation: "The type of renovation",
    criterias: "Your criteria",
    need_an_education: "Need an education",
    need_credit: "A works credit?",
    start_date: "Date of desired work",
    end_date: "Completion date of the desired work",
    type_of_object: "Type of object",
    manager_name: "Manager name",
    roomsRenovate: "The rooms to renovate:",
    square: "The surface of the property",
    type_of_extension: "Type of extension",
  },
  fr: {
    city: "Ville du bien a rénover ",
    state_of_object: "Etat actuel du bien",
    product_class: "Votre gamme de produit",
    need_an_architect: "Besoin d'un architecte d'intérieur",
    type_of_renovation: "Type de rénovation",
    criterias: "Vos critères",
    need_an_education: "Besoin d’un bureau d’etude",
    start_date: "Date de démarage souhaitée",
    end_date: "Date de fin des travaux souhaitée",
    type_of_object: "Type de bien",
    type_of_extension: "Type d'extension",
    manager_name: "Votre conseiller travaux",
    budget: "Votre budget",
    need_credit: "Un crédit travaux?",
    roomsRenovate: "Les pieces a rénover",
    detailsTitle: "Autres informations projet utiles",
    square: "Surface totale à rénover",
  },
};
