<template>
  <component :is="layout"></component>
</template>

<script>
import { UserService } from "@/services/user.service.js";
export default {
  name: "App",
  data() {
    return {
      defaultLayout: "Default",
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || this.defaultLayout) + "Layout";
    },
  },
  created() {
    this.$store.dispatch("fetchParams");
    if (localStorage.getItem("HABITAT_TOKEN")) {
      this.$store.commit("SET_LOADER_STATUS", true);
      UserService.getMe()
        .then(({ data }) => {
          this.$store.commit("authModule/SET_USER_PROFILE", data);
          this.$store.commit("authModule/SET_AUTHENTICATION_STATUS", true);
          this.$Tawk.$createChat({
            email: data.email,
            name: data.full_name,
          });
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
          this.$router.push({ name: "LoginPage" });
        })
        .finally(() => this.$store.commit("SET_LOADER_STATUS", false));
    }
  },
};
</script>

<style lang="scss" src="@/scss/main.scss"></style>
