import { protectedAxios } from "./helpers";
import { QuotaRoomService } from "@/models/service.model"

export class RoomServicesService {

  static getUnits() {
    return protectedAxios.get(`/units/`);
  }

  static getServices(category_id) {
    return protectedAxios.get(`/services/`, {
      params: { category_id }
    });
  }

  static getQuotaRoomService(quota_room_id) {
    return protectedAxios.get(`/quota_room_service/`, {
      params: { quota_room_id: Array.isArray(quota_room_id) ? quota_room_id?.join(',') : quota_room_id }
    });
  }

  static createQuotaRoomService({
    quota_room_id,
    category_id,
    service_id,
    custom_service_id,
    combination_id,
    amount,
    discount_percentage,
    discount_fixed,
    service_product_id = null,
  }) {
    return protectedAxios.post(`/quota_room_service/`, new QuotaRoomService({
      quota_room_id,
      category_id: +category_id,
      service_id,
      custom_service_id,
      service_combination_id: combination_id,
      amount,
      discount_percentage,
      discount_fixed,
      service_product_id,
    }));
  }

  static updateQuotaRoomService(quotaRoomId, {
    quota_room_id,
    category_id,
    service_id,
    custom_service_id,
    combination_id,
    amount,
    name,
    price,
    discount_percentage,
    discount_fixed
  }) {
    return protectedAxios.put(`/quota_room_service/${quotaRoomId}`, new QuotaRoomService({
      quota_room_id,
      category_id: +category_id,
      service_id,
      custom_service_id,
      service_combination_id: combination_id, 
      amount,
      name,
      price,
      discount_percentage,
      discount_fixed
    }));
  }

  static deleteQuotaRoomService(serviceId) {
    return protectedAxios.delete(`/quota_room_service/${serviceId}`);
  }

}

export class CustomRoomServicesService {

  static getServices(ownerId) {
    return protectedAxios.get(`/custom_service/`, {
      params: { owner_id: ownerId }
    });
  }

  static createService({ name, description, owner_id }) {
    return protectedAxios.post(`/custom_service/`, { name, description, owner_id });
  }

  static updateService(id, { name, description, default_price = 0, amount, category_id, quota_room_id, unit_id }) {
    return protectedAxios.put(`/services/${id}`, {
      name,
      description,
      default_price: default_price,
      amount,
      category_id,
      quota_room_id,
      unit_id
    });
  }

}

export class ServiceCombinationsService {
  static getServices(service_id) {
    return protectedAxios.get(`/service_combinations/`, {
      params: {
        service_id
      }
    });
  }
}
