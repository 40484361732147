import Vue from "vue";
import Vuex from "vuex";
import loaderModule from "./modules/loader";
import authModule from "./modules/auth";
import snackbarModule from "./modules/snackbar";
import quota from "./modules/quota";
import project from "./modules/project";
import services from "./modules/services";
import {
  getCompanyName,
  getFavicon,
  setCompanyName,
  setFavicon,
  setLogo,
  getLogo,
  setLogoMain,
  getLogoMain,
} from "../utils";
import { PlatformParameters } from "../services/project.service";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    platformParameters: {
      name: getCompanyName(),
      main_logo: getLogoMain(),
      logo: getLogo(),
      favicon: getFavicon(),
    },
  },
  mutations: {
    setPlatformParameters(state, payload) {
      if (payload && payload.name) {
        state.platformParameters = payload;
        setCompanyName(payload.name);
        setFavicon(payload.favicon);
        setLogo(payload.logo);
        setLogoMain(payload.main_logo);
      }
    },
  },
  actions: {
    async fetchParams({ commit }) {
      const { data } = await PlatformParameters.getParams();
      commit("setPlatformParameters", data);
    },
  },
  modules: {
    loaderModule,
    authModule: {
      namespaced: true,
      ...authModule,
    },
    snackbarModule: {
      namespaced: true,
      ...snackbarModule,
    },
    project: {
      namespaced: true,
      ...project
    },
    quota: {
      namespaced: true,
      ...quota,
    },
    services: {
      namespaced: true,
      ...services,
    },
  },
});
