export default {
  en: {
    email: "Email",
    password: "Password",
    search: "Search",
    searchCategory: "Search"
  },
  fr: {
    email: "Adresse E-mail",
    password: "Mot de passe",
    search: "Recherchez",
    searchCategory: "Que cherchez-vous?"
  },
};
