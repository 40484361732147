export default {
  state: {
    loadedProject: false,
    currentProject: null,
    currentProjectId: null,
    projects: [],
    users: [],
    currentUserId: null,
    currentUser: null
  },
  getters: {
    getLoadedProject(state) {
      return state.loadedProject
    },
    getCurrentProjectId(state) {
      return state.currentProject?.id || null
    },
    getCurrentProject(state) {
      return state.currentProject
    },
    getCurrentProjectAddress(state) {
      return {
        postal_code: state.currentProject?.postal_code || "",
        city: state.currentProject?.city || "",
        address: state.currentProject?.address_construction || "",
      }
    },
    getCurrentProjectName(state) {
      return state?.currentProject?.devis_name || "My own Home Sweet Home project"
    },
    getCurrentUserAddress(state) {
      return {
        phone: state?.currentUser?.phone || "",
        email: state?.currentUser?.email || "",
      }
    },
    getCurrentProjectOwnerId(state) {
      return state?.currentProject?.owner_id || null
    },
    getList(state) {
      return state.projects.filter(project => project.owner_id === state.currentUserId)
    },
    getListOfUser(state) {
      return state.users
    },
    getCurrentUserId(state) {
      return state.currentUserId
    },
  },
  mutations: {
    SET_LOADED_PROJECT(state) {
      state.loadedProject = true
    },
    SET_CURRENT_PROJECT_ID(state, projectId) {
      state.currentProjectId = projectId
      state.currentProject = state.projects.find(project => project.id === projectId) || null
    },
    SET_CURRENT_PROJECT(state, project) {
      state.currentProject = project;
      const currentProjectIndex = state.projects.findIndex((el => el.id === project.id))
      state.projects[currentProjectIndex] = project
      state.projects = [...state.projects]
    },
    SET_PROJECTS(state, projects) {
      state.projects = projects;
      if (!state.currentProjectId) {
        state.currentProject = state.currentProject ? state.currentProject : projects[0]
        state.currentUserId = state.currentProject ? state.currentProject?.owner_id : projects[0]?.owner_id
        state.currentUser = state.currentProject?.user || null 
      }
      if (state.currentProjectId) {
        state.currentProject = state.projects.find(project => project.id === state.currentProjectId) || projects[0]
        state.currentUserId = state.projects.find(project => project.id === state.currentProjectId)?.owner_id || projects[0]?.owner_id
      }
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    SET_CURRENT_USER_ID(state, currentUserId) {
      let userProject = state.projects.find(project => project.owner_id === currentUserId)
      state.currentUserId = currentUserId;

      if (userProject) {
        state.currentProject = userProject
        state.currentProjectId = userProject.id
      }
    }
  },
};
