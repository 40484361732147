export default {
  en: {
    email: "Email",
    password: "Password",
    repeatPassword: "Repeat password",
    newPassword: "New password",
    search: "Search"
  },
  fr: {
    email: "Email",
    password: "Mot de passe",
    repeatPassword: "Répéter mot de passe",
    newPassword: "Nouveau mot de passe",
    search: "Recherchez"
  },
};
