import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#FF9933",
        orange: "#FF9933",
        info: "#002d3d",
        sky: "#002d3d",
        grey: "#404040",
        "grey-lighten-1": "#777777",
        "grey-lighten-2": "#a3a3a3",
        "grey-lighten-3": "#d6d6d6",
        "grey-lighten-4": "#f4f4f4",
        error: "#d40000",
        success: "#00d84c",
      },
    },
  },
});
