export default {
  state: {
    loader: false,
  },
  getters: {
    getLoadingStatus(state) {
      return state.loader;
    },
  },
  mutations: {
    SET_LOADER_STATUS(state, payload) {
      state.loader = payload;
    },
  },
};
