export const pathImage = (value) => {
  if (!value || value === 'string') return
  return `${value}`
}

export const debounce = function(fn, delay) {
  let timeoutID = null
  return function() {
    clearTimeout(timeoutID)
    let args = arguments
    let that = this
    timeoutID = setTimeout(function() {
      fn.apply(that, args)
    }, delay)
  }
}

export const priceFormat = (price) => {
  return (price || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
}

export const getCompanyName = () => {
  const companyName = localStorage.getItem("company_name") || "Mon entreprise";
  const pageTitle = document.querySelector("title");
  if (pageTitle) {
    pageTitle.innerHTML = `${companyName}`;
  }
  return `${companyName}`;
};

export const setCompanyName = (name) => {
  localStorage.setItem("company_name", name);
  const pageTitle = document.querySelector("title");
  if (pageTitle) {
    pageTitle.innerHTML = `${name}`;
  }
};

export const getFavicon = () => {
  const favicon = localStorage.getItem("favicon") || "";
  const faviconDom = document.querySelector('link[rel="icon"]');
  if (favicon && faviconDom) {
    faviconDom.setAttribute("href", favicon);
  }
  return favicon;
};

export const setFavicon = (url) => {
  localStorage.setItem("favicon", url);
  const faviconDom = document.querySelector('link[rel="icon"]');
  if (url && faviconDom) {
    faviconDom.setAttribute("href", url);
  }
};

export const getLogo = () => {
  return localStorage.getItem("logo") || "/images/logo-placeholder.jpg";
};

export const setLogo = (url) => {
  localStorage.setItem("logo", url);
};

export const getLogoMain = () => {
  return localStorage.getItem("logo-main") || "/images/logo-placeholder.jpg";
};

export const setLogoMain = (url) => {
  localStorage.setItem("logo-main", url);
};
