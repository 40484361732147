import Vue from "vue";

let Tawk = {}
Tawk.install = function (Vue, options) {
  Vue.prototype.$Tawk = {}
  Vue.prototype.$Tawk.$destroyTawk = function () {
    let tawk = document.querySelector("body div > iframe");
    let script = document.querySelector(".TAWK_SCRIPT_CONTAINER");

    if (!tawk || !script) {
      console.log('Tawk not found', {
        tawk, script
      });
    } else {
      tawk = tawk.parentNode;

      script.parentNode.removeChild(script);
      tawk.parentNode.removeChild(tawk);

      delete window.$_Tawk;
      delete window.$_Tawk_AccountKey;
      delete window.$_Tawk_Unstable;
      delete window.$_Tawk_WidgetId;
      delete window.$__TawkEngine;
      delete window.$__TawkSocket;
      delete window.TawkClass;
      delete window.Tawk_API;

      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
  }
  Vue.prototype.$Tawk.$createChat = function (user) {
    let Tawk_API = {}
    let Tawk_LoadStart = new Date()
    let s1 = document.createElement("script")
    let s0 = document.getElementsByTagName("script")[0]
    s0.classList.add("TAWK_SCRIPT_CONTAINER");
    s1.async = true
    s1.src = options.tawkSrc
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
    window.Tawk_API = Tawk_API
    if (!user) return
    window.Tawk_API.onLoad = function () {
      window.Tawk_API.setAttributes({
        'name': user.name,
        'email': user.email,
      }, function (error) {
        console.log(error)
      })
    }
  }
  Vue.prototype.$Tawk.$updateChatUser = function (user) {
    if (!user) return
    window.Tawk_API.onLoad = function () {
      window.Tawk_API.setAttributes({
        'name': user.name,
        'email': user.email,
        'hash': user.emailHmac
      }, function (error) {
        console.log(error)
      })
    }
  }
  Vue.prototype.$Tawk.$endChat = function () {
    window.Tawk_API.endChat()
  }
  Vue.prototype.$Tawk.$isInit = function () {
    if (window.Tawk_API) {
      return true
    }
    return false
  }

}

Vue.use(Tawk, {
  tawkSrc: process.env.VUE_APP_TAWK_SRC
})