import axios from "axios";
import router from '@/router/index.js'
import store from '@/store/index.js'

export const baseAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URI,
});

let axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URI
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = `Bearer ${localStorage.getItem("HABITAT_TOKEN")}`;
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error)
  },
);

axiosInstance.interceptors.response.use((response) => {
  return response;
}, ({response}) => {
  if (response.status === 403 || response.status === 401) {
    localStorage.removeItem("HABITAT_TOKEN")
    router.push({name: 'LoginPage'})
    store.commit("snackbarModule/SET_ERROR_API", response.status)
  }
  return Promise.reject(response);
});



export const protectedAxios = axiosInstance
