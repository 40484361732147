export default {
  en: {
    loginPage: {
      formTitle: "Welcome to your private area",
      forgotPassword: "Forgot password",
      dontHaveAnAccount: "Don't have and account?",
      registerNow: "Register now",
    },
    signUpPage: {
      formTitle: "Subscribe",
      alreadyHaveAnAccount: "Already have an account?",
    },
    forgotPasswordPage: {
      formTitle: "Forgot your password",
    },
    resetPasswordPage: {
      formTitle: "SET YOUR PASSWORD",
      formSubtitle:
        "Please enter your new password twice so we can verify you typed it in correctly.",
    },
  },
  fr: {
    loginPage: {
      formTitle: "Se connecter pour commencer",
      forgotPassword: "Mot de passe oublié?",
      dontHaveAnAccount: "Vous n'avez pas de compte ?",
      registerNow: " S'inscrire",
    },
    signUpPage: {
      formTitle: "Inscription",
      alreadyHaveAnAccount: "Vous avez déjà un compte?",
    },
    forgotPasswordPage: {
      formTitle: "Mot de passe oublié",
    },
    resetPasswordPage: {
      formTitle: "Nouveau mot de passe",
      formSubtitle:
        "Veuillez saisir votre nouveau mot de passe deux fois afin que nous puissions vérifier que vous l'avez saisi correctement.",
    },
  },
};
