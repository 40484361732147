import { RoomServicesService } from "@/services/roomServices.sevice.js";
import { QuotaRoomProductsService } from "@/services/product.service.js";

import groupBy from "lodash.groupby";
import uniqBy from "lodash.uniqby";
import orderBy from "lodash.orderby";

class Service {
  constructor({ description = null, id = null, name = null, tax = 0 }) {
    this.description = description;
    this.id = id;
    this.tax = tax;
    this.name = name;
    this.basedOnRoomService = true;
  }
}

export default {
  state: {
    services: [],
    servicesFromRoomServices: [],
    roomServices: [],
    roomProducts: [],
    roomProductsByRoomServiceId: [],
    combinationsByServiceId: [],
  },
  getters: {
    filterByCategoryId(state) {
      return (catId) => {
        return state.service.filter((s) => s.category_id === catId);
      };
    },
    list: (state) => (catId) => {
      let services = [];
      state.services.forEach((service) => {
        let roomServices =
          state.roomServices.filter((roomService) => {
            return (
              (roomService.service_id === service.id &&
                roomService.category_id === catId) ||
              (roomService.custom_service_id === service.id &&
                roomService.category_id === 1)
            );
          }) || [];

        roomServices = orderBy(roomServices, ["id"], ["asc"]);

        if (roomServices.length > 0) {
          roomServices.forEach((roomService) => {
            services.push({
              ...service,
              roomService,
              roomProduct:
                state.roomProducts?.find((roomProduct) => {
                  return (
                    (roomProduct.service_id === service.id &&
                      roomProduct.quota_room_service_id === roomService?.id) ||
                    null
                  );
                }) || null,
              notAllowedCombinations:
                state.combinationsByServiceId[service.id] || [],
            });
          });
        }

        if (roomServices.length === 0) {
          services.push({
            ...service,
            roomService: null,
            roomProduct: null,
            notAllowedCombinations: [],
          });
        }
      });

      return services;
    },
  },
  mutations: {
    SET_SERVICES(state, list) {
      state.services = list;
    },
    SET_ROOM_SERVICES(state, list) {
      state.roomServices = list;
    },
    SET_SERVICES_FROM_ROOM_SERVICES(state, catId) {
      state.servicesFromRoomServices = state.roomServices
        .filter((s) => s.category_id !== 1 && s.category_id === catId)
        .map(({ service }) => {
          return new Service(service);
        });
    },
    CLEAR_SERVICES_FROM_ROOM_SERVICES(state) {
      state.servicesFromRoomServices = [];
    },
    SET_ROOM_PRODUCTS(state, list) {
      state.roomProducts = list;
    },
    GROUP_COMBINATIONS_BY_SERVICE_ID(state, payload) {
      state.combinationsByServiceId = !payload
        ? groupBy(
            state.roomServices.map((rs) => {
              return {
                roomServiceId: rs.id,
                service_id: rs.service_id,
                service_combination_id: rs.service_combination_id,
              };
            }),
            "service_id"
          )
        : [];
    },
    GROUP_ROOM_PRODUCTS_BY_ROOM_SERVICE_ID(state, payload) {
      state.roomProductsByRoomServiceId = !payload
        ? groupBy(state.roomProducts, "quota_room_service_id")
        : [];
    },
  },
  actions: {
    async getServices({ commit }, catId) {
      if (!catId) return;
      const { data } = await RoomServicesService.getServices(catId);
      commit("SET_SERVICES", data);
    },
    async getQuotaRoomServices({ commit }, roomId) {
      if (!roomId) return;
      const { data } = await RoomServicesService.getQuotaRoomService(roomId);
      commit("SET_ROOM_SERVICES", data);
    },
    async getQuotaRoomServicesByCategoryId({ commit }, { roomId, catId }) {
      if (!roomId) return;
      try {
        const { data } = await RoomServicesService.getQuotaRoomService(roomId);
        const list = data.filter((s) => s.category_id === catId);
        commit("SET_ROOM_SERVICES", list);
      } catch (ex) {
        console.error(ex);
      }
    },
    async getQuotaRoomProducts({ commit }, roomId) {
      if (!roomId) return;
      const { data } = await QuotaRoomProductsService.getProducts(roomId);
      commit("SET_ROOM_PRODUCTS", data);
    },
    async dataServicesOfCategory(
      { state, commit, dispatch },
      { catId, roomId }
    ) {
      await dispatch("getServices", catId);
      await dispatch("getQuotaRoomServices", roomId);
      commit("GROUP_COMBINATIONS_BY_SERVICE_ID");
      await dispatch("getQuotaRoomProducts", roomId);
      commit("GROUP_ROOM_PRODUCTS_BY_ROOM_SERVICE_ID");
      commit("SET_SERVICES_FROM_ROOM_SERVICES", catId);
      commit(
        "SET_SERVICES",
        uniqBy([...state.services, ...state.servicesFromRoomServices], "id")
      );
    },
    async clearDataServicesOfCategory({ commit }) {
      commit("SET_SERVICES", []);
      commit("SET_ROOM_SERVICES", []);
      commit("CLEAR_SERVICES_FROM_ROOM_SERVICES");
      commit("SET_ROOM_PRODUCTS", []);
      commit("GROUP_COMBINATIONS_BY_SERVICE_ID", []);
      commit("GROUP_ROOM_PRODUCTS_BY_ROOM_SERVICE_ID", []);
    },
  },
};
