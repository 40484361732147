import { QuotaService } from "@/services/quotas.service.js";
export default {
  state: {
    currentQuota: null,
    statuses: null,
    quotaData: null,
    pdfContent: {
      main_logo: `${process.env.VUE_APP_PRINT_IMAGE}/logo-placeholder.jpg`,
      team: null,
      conditions: null,
    },
  },
  getters: {
    getCurrentQuota(state) {
      return state.currentQuota;
    },
    getTotal(state) {
      let currentQuota = state.currentQuota;
      let currentQuotaPriceWithTax = currentQuota?.price_with_tax
        ? currentQuota.price_with_tax
        : 0;
      return currentQuota?.discount_price
        ? currentQuota?.discount_price
        : currentQuotaPriceWithTax;
    },
    getQuotaStatusText(state) {
      return state.statuses && state.currentQuota?.custom_status
        ? state.statuses[state.currentQuota.custom_status]
        : "Validation du devis par un conseiller";
    },
    getQuotaStatus(state) {
      return state.currentQuota?.custom_status || -1;
    },
    isQuotaFreese(state) {
      // freese for customer
      return !state.currentQuota?.edit || false;
    },
    isQuotaValidated(state) {
      return state.currentQuota?.custom_status === 5 || false;
    },
    isQuotaSigned(state) {
      return state.currentQuota?.custom_status === 7 || false;
    },
    getCurrentQuotaName(state) {
      return state?.currentQuota?.name || null;
    },
    getCurrentQuotaId(state) {
      return state?.currentQuota?.id;
    },
  },
  mutations: {
    SET_CURRENT_QUOTA(state, quota) {
      state.currentQuota = quota;
    },
    SET_CURRENT_QUOTA_NAME(state, name) {
      if (state.currentQuota) {
        state.currentQuota.name = name;
      }
    },
    SET_QUOTA_STATUSES(state, statuses) {
      state.statuses = statuses;
    },
    SET_QUOTA_DATA(state, data) {
      state.quotaData = data;
    },
    SET_PDF_CONTENT(state, data) {
      if (data) {
        state.pdfContent = {
          main_logo:
            data.main_logo ??
            `${process.env.VUE_APP_PRINT_IMAGE}/logo-placeholder.jpg`,
          team: data.team,
          conditions: data.conditions,
        };
      }
    },
  },
  actions: {
    getQuotaById({ state, commit, dispatch }, quotaId) {
      if (!quotaId) return;
      return QuotaService.getQuotaById(quotaId)
        .then(({ data }) => {
          if (!state.statuses) {
            dispatch("getStatuses");
          }
          commit("SET_CURRENT_QUOTA", data)
          commit("project/SET_CURRENT_PROJECT_ID", data?.project_id, {
            root: true,
          });
          return data;
        })
        .catch(({ response }) => {
          commit("snackbarModule/SET_ERROR_API", response, { root: true });
        });
    },
    getStatuses({ commit }) {
      return QuotaService.getStatuses().then(({ data }) => {
        commit("SET_QUOTA_STATUSES", data);
      });
    },
    async getQuotaData({ state, commit }) {
      const quotaId = state.currentQuota.id;
      try {
        const { data } = await QuotaService.getQuotaData(quotaId);
        if (data.length > 0) {
          const quotaSum = JSON.parse(data[0].data);
          commit("SET_QUOTA_DATA", quotaSum);
          return;
        }
        commit("SET_QUOTA_DATA", null);
      } catch (error) {
        commit("snackbarModule/SET_ERROR_API", "Erreur", { root: true });
      }
    },
    async getPdfContent({ commit }) {
      try {
        const { data } = await QuotaService.pdfContent();
        commit("SET_PDF_CONTENT", data);
      } catch (error) {
        console.log("TODO", error);
        commit("snackbarModule/SET_ERROR_API", "Erreur", { root: true });
      }
    },
  },
};
