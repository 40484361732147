import { protectedAxios } from "./helpers";

export class ProductsService {
    static getProductById(productId) {
        return protectedAxios.get(`/products/${productId}`);
    }
}

export class CustomProductsService {
    static createProduct({
        name,
        description,
        reference,
        mark,
        price,
        client_price,
        owner_id,
        site_url,
    }) {
        const params = new URLSearchParams();
        params.append("name", name);
        params.append("description", description);
        params.append("reference", reference);
        params.append("mark", mark);
        price && params.append("price", price);
        owner_id && params.append("owner_id", owner_id);
        client_price && params.append("client_price", client_price);
        site_url && params.append("site_url", site_url);

        return protectedAxios.post(`/custom_products/`, params);
    }

    static updateProduct(productId, {
        name,
        description,
        reference,
        mark,
        price,
        client_price,
        site_url
    }) {
        const params = new URLSearchParams();
        params.append("name", name);
        params.append("description", description);
        params.append("reference", reference);
        params.append("mark", mark);
        price && params.append("price", price);
        client_price && params.append("client_price", client_price);
        site_url && params.append("site_url", site_url);

        return protectedAxios.put(`/custom_products/${productId}`, params);
    }

    static getProductById(productId) {
        return protectedAxios.get(`/custom_products/${productId}`);
    }

    static deleteProduct(productId) {
        return protectedAxios.delete(`/custom_products/${productId}`);
    }
}

export class ServiceProductsService {
    static getProducts(service_id) {
        return protectedAxios.get(`/service_products/`, {
            params: {
                service_id
            }
        });
    }
}

export class ServiceProductPriceService {
    static getProductPrices(service_product_id) {
        return protectedAxios.get(`/service_product_prices/`, {
            params: { service_product_id }
        });
    }
}

export class QuotaRoomProductsService {
    static getProducts(quota_room_id) {
        return protectedAxios.get(`/quota_room_products/`, {
            params: { quota_room_id: Array.isArray(quota_room_id) ? quota_room_id?.join(',') : quota_room_id }
        });
    }
    static createProduct({
        quota_room_id,
        service_id,
        service_product_id,
        custom_product_id,
        quota_room_service_id,
        discount_percentage,
        discount_fixed
    }) {
        return protectedAxios.post(`/quota_room_products/`, {
            quota_room_id,
            service_id,
            service_product_id,
            custom_product_id,
            quota_room_service_id,
            discount_percentage,
            discount_fixed
        });
    }
    static updateProduct(productPriceId, {
        quota_room_id,
        service_id,
        service_product_id,
        custom_product_id,
        quota_room_service_id,
        discount_percentage,
        discount_fixed
    }) {
        return protectedAxios.put(`/quota_room_products/${productPriceId}`, {
            quota_room_id,
            service_id,
            service_product_id,
            custom_product_id,
            quota_room_service_id,
            discount_percentage,
            discount_fixed
        });
    }
    static deleteProduct(productPriceId) {
        return protectedAxios.delete(`/quota_room_products/${productPriceId}`);
    }
}