export default [
  {
    path: "/room",
    component: () => import("@/views/Room/RoomRouterView"),
    children: [
      {
        path: ":roomId",
        name: "RoomId",
        component: () => import("@/views/Room/RoomId/RoomIdPage"),
        meta: {
          auth: true,
          pageInfo: {
            title: "Votre project",
            subtitle: "My own Home Sweet Home project",
            subtitleGetterVuex: 'quota/getCurrentQuotaName',
          }
        }
      },
      {
        path: ":roomId/services/:categoryId",
        name: "ServiceCategoryId",
        component: () => import("@/views/Room/RoomId/RoomIdPage"),
        meta: {
          auth: true,
          pageInfo: {
            title: "Votre project",
            subtitle: "Le chiffrage de mon projet",
            subtitleGetterVuex: 'quota/getCurrentQuotaName',
          },
        }
      },
    ],
  },
];
