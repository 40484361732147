import router from "@/router/index.js";
export default {
  state: {
    authenticated: false,
    accessToken: localStorage.getItem("HABITAT_TOKEN") || null,
    userProfile: {
      full_name: '',
      is_superuser: localStorage.getItem("HABITAT_IS_ADMIN") === 'true' || null
    },
  },
  getters: {
    getAuthenticationStatus(state) {
      return state.authenticated;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getUserProfile(state) {
      return state.userProfile;
    },
    isSuperUser(state) {
      return state.userProfile?.is_superuser
    }
  },
  mutations: {
    SET_AUTHENTICATION_STATUS(state, payload) {
      state.authenticated = payload;
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
      localStorage.setItem("HABITAT_TOKEN", state.accessToken);
      payload ? (state.authenticated = true) : (state.authenticated = false);
    },
    SET_USER_PROFILE(state, payload) {
      state.userProfile = payload;
      localStorage.setItem("HABITAT_IS_ADMIN", payload?.is_superuser);
    },
    CLEAR_USER_PROFILE(state) {
      state.userProfile = null;
    },
    LOGOUT() {
      localStorage.removeItem("HABITAT_TOKEN");
      localStorage.removeItem("HABITAT_IS_ADMIN");
      router.go(0);
    },
  },
};
