export default [
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/Auth/LoginPage/LoginPage"),
    meta: {
      layout: "FullScreen",
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/Auth/ForgotPassword/ForgotPasswordPage"),
    meta: {
      layout: "FullScreen",
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/Auth/ResetPassword/ResetPasswordPage"),
    meta: {
      layout: "FullScreen",
      onlyWhenLoggedOut: true,
    },
  },
];
