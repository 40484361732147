import Vue from "vue";
import VueRouter from "vue-router";
import AuthRoutes from "./authRoutes";
import ProjectRoutes from "./projectRoutes.js";
import QuotaRoutes from "./quotaRoutes.js";
import RoomRoutes from "./roomRoutes.js";
import DocumentsRoutes from "./documentRoutes.js";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
    meta: {
      auth: true,
      pageInfo: {
        title: "Votre estimation",
        subtitleGetterVuex: 'project/getCurrentProjectName',
      },
    },
  },
  {
    path: "/me",
    name: "Account",
    meta: {
      auth: true,
      pageInfo: {
        title: "Mon compte",
        subtitle: "L'enseble de mes informations",
      },
    },
    component: () => import("@/views/Account/AccountPage"),
  },
  {
    path: "*",
    component: () => import("~ui/NotFound/NotFound"),
    meta: {
      layout: "FullScreen",
    },
  },
  ...AuthRoutes,
  ...ProjectRoutes,
  ...QuotaRoutes,
  ...RoomRoutes,
  ...DocumentsRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const onlyWhenLoggedIn = to.matched.some((record) => record.meta.auth);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = window.localStorage.getItem("HABITAT_TOKEN");

  if (onlyWhenLoggedIn && !loggedIn) {
    return next({
      path: "/login",
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next("/");
  }

  next();
});

export default router;
