const meta = {
  auth: true,
  pageInfo: {
    title: "Votre project",
    subtitle: "My own Home Sweet Home project",
  },
}

const metaQuotaPreview = {
  auth: true,
  pageInfo: {
    title: "Mon devis",
    subtitle: "Le chiffrage de mon project",
    subtitleGetterVuex: 'quota/getCurrentQuotaName',
  }
}

export default [
  {
    path: "/quota",
    component: () => import("@/views/Quota/QuotaRouterView"),
    children: [
      {
        path: "",
        name: "QuotaList",
        component: null,
        meta,
        redirect: { name: 'Home' }
      },
      {
        path: ":quotaId",
        component: () => import("@/views/Quota/QuotaId/QuotaIdPageRouterView"),
        meta,
        children: [
          {
            path: "",
            name: "QuotaAddDocument",
            component: () => import("@/views/Quota/QuotaId/QuotaAddDocumentPage"),
            meta: metaQuotaPreview
          },
          {
            path: "pre",
            name: "QuotaPreValidation",
            component: () => import("@/views/Quota/QuotaId/QuotaPreValidationPage"),
            meta: metaQuotaPreview
          },
          {
            path: "visit-master",
            name: "QuotaVisitMaster",
            component: () => import("@/views/Quota/QuotaId/QuotaVisitMasterPage"),
            meta: metaQuotaPreview
          },
          {
            path: "sum",
            name: "QuotaSum",
            component: () => import("@/views/Quota/QuotaId/QuotaSumPage"),
            meta: metaQuotaPreview
          },
        ]
      },
    ],
  },
];
