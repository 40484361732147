<template>
  <v-app>
    <AppHeader></AppHeader>
    <Loader v-if="$store.getters.getLoadingStatus"></Loader>
    <Snackbar></Snackbar>
    <div>
      <transition name="fade" mode="out-in">
        <router-view class="child-view" />
      </transition>
    </div>
    <AppAction
      :style="{ zIndex: noProject || callMeDialog ? 205 : 1 }"
      @showCallMe="callMeDialog = true"
    />
    <AppNoProjectModal v-if="!$store.getters['authModule/isSuperUser']" :show="noProject" />
    <AppCallMeModal
      v-if="$store.getters['authModule/getAuthenticationStatus']"
      :show="callMeDialog"
      @closeModal="callMeDialog = false"
    />
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {
    AppHeader: () => import("~cp/AppHeader/AppHeader"),
    AppAction: () => import("~cp/AppAction/AppAction"),
    AppNoProjectModal: () => import("~cp/AppNoProjectModal/AppNoProjectModal"),
    AppCallMeModal: () => import("~cp/AppCallMeModal/AppCallMeModal.vue"),
    Loader: () => import("~ui/Loader/Loader"),
    Snackbar: () => import("~ui/Snackbar/Snackbar"),
  },
  data() {
    return {
      callMeDialog: false,
    };
  },
  computed: {
    noProject() {
      return (
        this.$store.getters["project/getCurrentProjectId"] === null &&
        this.$store.getters["project/getLoadedProject"]
      );
    },
  },
};
</script>

<style src="./Animation.scss" lang="scss" scoped></style>
