import { protectedAxios, baseAxios } from "./helpers";
import { format } from 'date-fns'

export class ProjectService {

  static getProjects() {
    return protectedAxios.get(`/projects/`, { params: { limit: 9999 } });
  }

  static createProject(project) {
    return protectedAxios.post(`/projects`, project);
  }

  static getProjectDicts() {
    return protectedAxios.get(`/projects/dicts`);
  }

  static getProjectById(projectId) {
    return protectedAxios.get(`/projects/${projectId}`);
  }

  static updateProjectById(projectId, project) {
    return protectedAxios.put(`/projects/${projectId}`, project);
  }

  static getProjectHistory({quota_id, status_id = 4 }) {
    return protectedAxios.get(`/project_histories/`, {
      params: {
        quota_id: Array.isArray(quota_id) ? quota_id?.join(',') : quota_id,
        status_id: Array.isArray(status_id) ? status_id?.join(',') : status_id,
      }
    });
  }

  static createProjectHistory({ quota_id, status_id = 2, date = format(new Date(), 'yyyy-MM-dd') }) {
    return protectedAxios.post(`/project_histories/`, { quota_id, status_id, date });
  }
}

export class PlatformParameters {
  static getParams() {
    return baseAxios.get(`/platform_parameters/`);
  }
}
