export default [
  {
    path: "/project",
    component: () => import("@/views/Project/ProjectRouterView"),
    children: [
      {
        path: "",
        name: "ProjectList",
        component: () => import("@/views/Project/ProjectList/ProjectListPage"),
      },
      {
        path: ":projectId",
        name: "ProjectId",
        component: () => import("@/views/Project/ProjectId/ProjectIdPage"),
      },
    ],
  },
];
