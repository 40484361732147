export default {
  en: {
    error: {
      base: "Error",
      incorrectEmailOrPassword: "Incorrect email or password"
    },
    success: {
      base: "Success",
      passwordReset: "Successful password reset!",
      passwordForgot:
        " We sent you the confirmation email. Please confirm the reset",
      update: "Update successful",
      delete: "Delete successful",
      sendToManager: "Your quote has been sent to your advisor",
    },
  },
  fr: {
    error: {
      base: "Erreur",
      incorrectEmailOrPassword: "Email ou mot de passe incorrect"
    },
    success: {
      base: "Succès",
      passwordReset: "Réinitialisation du mot de passe réussie!",
      passwordForgot:
        "Nous vous avons envoyé l'e-mail de confirmation. Veuillez confirmer la réinitialisation",
      update: "Mise à jour réussie",
      delete: "Suppression réussie",
      sendToManager: "Votre devis a bien été envoyé a votre conseiller"
    },
  },
};
