import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import "./plugins/tawk";

Vue.config.productionTip = false;

// Layouts

import Default from "./layouts/Default";
import FullScreen from "./layouts/FullScreen";

Vue.component("DefaultLayout", Default);
Vue.component("FullScreenLayout", FullScreen);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
