import userActions from "./userActions";
import validations from "./validations";
import placeholders from "./placeholders";
import authPages from "./authPages";
import header from "./header";
import project from "./project";
import labels from "./labels";
import snackbar from "./snackbar";

const en = {
  validations: {
    ...validations.en,
  },
  labels: {
    ...labels.en,
  },
  placeholders: {
    ...placeholders.en,
  },
  authPages: {
    ...authPages.en,
  },
  userActions: {
    ...userActions.en,
  },
  header: {
    ...header.en,
  },
  project: {
    ...project.en,
  },
  snackbar: {
    ...snackbar.en,
  },
};

const fr = {
  validations: {
    ...validations.fr,
  },
  labels: {
    ...labels.fr,
  },
  placeholders: {
    ...placeholders.fr,
  },
  authPages: {
    ...authPages.fr,
  },
  userActions: {
    ...userActions.fr,
  },
  header: {
    ...header.fr,
  },
  project: {
    ...project.fr,
  },
  snackbar: {
    ...snackbar.fr,
  },
};

export default { en, fr };
