import { baseAxios, protectedAxios } from "./helpers";

export class UserService {
  static logInGetToken({ username, password }) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return baseAxios.post("/login/access-token", params);
  }

  static changePassword({ oldPassword, newPassword }) {
    return protectedAxios.post(`/login/change-password`, {
      old_password: oldPassword,
      new_password: newPassword
    });
  }

  static getUsers() {
    return protectedAxios.get(`/users/`, { params: { limit: 9999 } });
  }

  static getMe() {
    return protectedAxios.get(`/users/me`);
  }

  static updateMe(profile) {
    return protectedAxios.put(`/users/me`, profile);
  }

  static updateUser(userId, {phone}) {
    return protectedAxios.put(`/users/${userId}`, { phone });
  }

  static passwordRecovery(email) {
    return baseAxios.post(`/password-recovery/${email}`);
  }

  static resetPassword({ password, token }) {
    return baseAxios.post(`/reset-password/`, {
      new_password: password,
      token,
    });
  }
}
