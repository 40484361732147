export default {
  state: {
    error: null,
    errorAPI: null,
    success: null,
    warning: null,
  },
  getters: {
    getError(state) {
      return state.error;
    },
    getErrorAPI(state) {
      return state.errorAPI;
    },
    getSuccess(state) {
      return state.success;
    },
    getWarning(state) {
      return state.warning;
    },
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    SET_ERROR_API(state, error) {
      state.errorAPI = error;
    },
    CLEAR_ERROR_API(state) {
      state.errorAPI = null;
    },
    SET_SUCCESS_MESSAGE(state, payload) {
      state.success = payload;
    },
    CLEAR_SUCCESS_MESSAGE(state) {
      state.success = null;
    },
    SET_WARNING_MESSAGE(state, payload) {
      state.warning = payload;
    },
    CLEAR_WARNING_MESSAGE(state) {
      state.warning = null;
    },
  },
};
