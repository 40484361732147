export default {
  en: {
    welcome: "Welcome",
    menu: "Menu",
    myAccount: "My account",
    myProjects: "My projects",
    myDoc: "My Documents",
  },
  fr: {
    welcome: "Bienvenue",
    menu: "Menu",
    myAccount: "Mon compte",
    myProjects: "Mes Projets",
    myDoc: "Mes Documents",
  },
};
