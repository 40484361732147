export default {
  en: {
    required: "Required",
    email: "Enter a valid email",
    onlyAlphabeticChars: "Only alphabetic characters are allowed",
    onlyNumericChars: "Only numbers are allowed",
    onlyNumericAndSymbols: "Only numbers and symbols +, (, ) are allowed",
    exactChars: "Value should contain exactly {count} chars",
    inRangeChars: "Value should contain chars in range from {from} to {to}",
    password: {
      tooShort: "The password is too short",
      noNumber: "The password must contain at least one number",
      noLetters: "The password must contain at least one letter",
      noSmallLetters: "The password must contain at least one lowercase letter",
      noBigLetters: "The password must contain at least one uppercase letter",
      noSpecialSymbols: "The password must contain at least one special symbol",
      passwordDontMatch: "Passwords don't match",
    },
    url: "URL isn't valid",
  },
  fr: {
    required: "Requis",
    email: "Entrer une adresse email valide",
    onlyAlphabeticChars: "Uniquement caractères alphabétiques",
    onlyNumericChars: "Uniquement des chiffres",
    onlyNumericAndSymbols: "Sont permis chiffres et symboles + -  ( ) ",
    exactChars: "La valeur doit contenir exactement {count} caractères",
    inRangeChars:
      "La valeur doit contenir des caractères compris entre {from} et {to}",
    password: {
      tooShort: "Le mot de passe est trop court",
      noNumber: "Le mot de passe doit contenir au moins un chiffre",
      noLetters: "Le mot de passe doit contenir au moins une lettre",
      noSmallLetters:
        "Le mot de passe doit contenir au moins une lettre minuscule",
      noBigLetters:
        "Le mot de passe doit contenir au moins une lettre majuscule",
      noSpecialSymbols:
        "Le mot de passe doit contenir au moins un caractère spéciale",
      passwordDontMatch: "Les mots de passe ne correspondent pas",
    },
    url: "L'URL n'est pas valide",
  },
};
