export class QuotaRoomService {
    constructor(service = {}) {
        this.quota_room_id = service.quota_room_id
        this.discount_percentage = service.discount_percentage
        this.discount_fixed = service.discount_fixed
        this.category_id = service.category_id || 1
        this.service_product_id = service.service_product_id || null
        if (service.service_id) {
            this.service_id = service.service_id
        }
        if (service.custom_service_id) {
            this.custom_service_id = service.custom_service_id
        }
        if (service.service_combination_id) {
            this.service_combination_id = service.service_combination_id
        }
        if (service.price) {
            this.price = service.price
        }
        if (service.name) {
            this.name = service.name
        }
        this.amount = service.amount || 0
    }
}